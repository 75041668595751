import React, { useState } from "react"
import * as Styled from "./styled"
import { OnChangeCallback } from "react-toastify/dist/core"

interface popUpProps{
  onCancel: () => void
  onSave: (e : number) => void
  goal : number
}

function PopUp({onCancel, onSave, goal} : popUpProps){
  const [newGoal, setNewGoal] = useState<number>(0)

  function handleGoalChange(e : React.ChangeEvent<HTMLInputElement>){
    e.stopPropagation()
    setNewGoal(parseInt(e.target.value))
  }

  return(
    <Styled.PopUpOverlay>
      <Styled.PopUp>
        <h1><Styled.Strong>Editar Meta:</Styled.Strong> Posto {1} (Célula {1})</h1>
          <Styled.showWork>
            <h2>WORKSTATION</h2>
            <Styled.Input>
              <label><Styled.Strong>Meta</Styled.Strong> (Qtd. Peças)</label>
              <input type="text" placeholder={goal.toString()} onChange={(e) => handleGoalChange(e)}/>
            </Styled.Input>
          </Styled.showWork> 
          <Styled.Buttons>
            <Styled.CancelButton onClick={onCancel}>Cancelar</Styled.CancelButton>
            <Styled.SaveButton onClick={() => onSave(newGoal)}>Salvar</Styled.SaveButton>
          </Styled.Buttons>
      </Styled.PopUp>
    </Styled.PopUpOverlay>
    
  )

}

export default PopUp