import { useEffect, useState } from "react";
import * as Styled from "./styled";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const TopTooltip = ({ title, display, children }: any) => {

  if (!display) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <BootstrapTooltip title={title} placement="top">
      { children }
    </BootstrapTooltip>
  );
};

export default TopTooltip;

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} title={<Styled.TooltipText>{props.title}</Styled.TooltipText>} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
