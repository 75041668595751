import React from 'react';
import * as Styled from './styled';
import LightLogo from '../LightLogo';
import { useHistory, useLocation } from 'react-router-dom';
import { HomeOutlined, AssignmentOutlined, AssessmentOutlined, ContactPageOutlined, DescriptionOutlined,
        SettingsOutlined, ExitToApp, AppRegistrationOutlined } from '@mui/icons-material/';
import Login from '../../pages/login';

const SideMenu = ({ toggleMenu, hidden, navigation }: any) => {

  const history = useHistory();
  const { pathname } = useLocation();

  if (hidden) {
    return (<></>);
  }


  return (
    <Styled.Container onClick={event => handleAreaClick(event, toggleMenu)}>
      <Styled.Menu>
        <Styled.Header>
          <Styled.HamburgerMenu onClick={toggleMenu}>
            <div></div>
            <div></div>
            <div></div>
          </Styled.HamburgerMenu>
        </Styled.Header>
        <Styled.MenuItems>
          <li>
            <button 
              className={`${['/dashboard', '/'].includes(pathname) ? 'active' : ''}`} 
              onClick={() => goTo('/dashboard', history)}
              >
              <HomeOutlined/>Dashboard
            </button>
          </li>
          <li>
            <button 
              className={`${pathname === '/cells' ? 'active' : ''}`} 
              onClick={() => goTo('/cells', history)}
            >
              <AssignmentOutlined/>Células de produção
            </button>
          </li>
          <li>
            <button 
              className={`${pathname === '/cells' ? 'active' : ''}`} 
              onClick={() => goTo('/cells/register-goals', history)}
            >
              <AppRegistrationOutlined/>Registro de metas
            </button>
          </li>
          <li>
            <button 
              className={`${pathname === '/cells' ? 'active' : ''}`} 
              onClick={() => goTo('/cells/view-goals', history)}
            >
              <AssessmentOutlined/>Visualização de metas
            </button>
          </li>
          <li>
            <button 
              className={`${pathname === '/users' ? 'active' : ''}`} 
              onClick={() => goTo('/users', history)}
            >
              <ContactPageOutlined/>Gestão de usuários
            </button>
          </li>
          <li>
            <button 
              className={`${pathname === '/cells' ? 'active' : ''}`} 
              onClick={() => goTo('/#', history)}
            >
              <DescriptionOutlined/>Relatórios
            </button>
          </li>
          <li> </li>
          {/* <li> Remover o li vazio de cima quando ativar a opção de configurações
            <button 
              className={`${pathname === '/cells' ? 'active' : ''}`} 
              onClick={() => goTo('/#', history)}
            >
              <SettingsOutlined/>Configurações
            </button>
          </li> */}
          <li>
            <button 
              className={`${pathname === '/login' ? 'active' : ''}`} 
              onClick={() => goTo('/login', history)}
              
            >
              <ExitToApp/>Sair
            </button>
          </li>
        </Styled.MenuItems>
      </Styled.Menu>
    </Styled.Container>
    );
}

export default SideMenu;


function goTo(uri: string, history: any) {
  history.replace(uri);
}

function handleAreaClick(event: any, toggleMenu: any) {
  if (event.pageX > 250) {
    toggleMenu();
  }
}