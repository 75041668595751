import React, { useState } from 'react';
import AuthenticatedLayout from "../../_layouts/Authenticated";
import GoBackButton from "../../../components/buttons/GoBack";
import AccentButton from "../../../components/buttons/AccentButton";
import * as Styled from "./styled";
import { Row } from '../../../components/Row/styled';
import CreateCell from '../../../components/forms/createCell';
import StyleCellsView from '../../../components/buttons/StyleView';
import AddStation from '../../../components/buttons/AddStation';
import FieldStation from '../../../components/FieldStation';
import Summary from '../../../components/Summary';
import { useHistory, useLocation } from 'react-router-dom';

import img1 from './imgs/Rectangle 2428.png';
import img2 from './imgs/Rectangle 2429.png';
import img3 from './imgs/Rectangle 2420.png';
import NewCellDto from '../../../dto/new-cell.dto';
import WorkstationDto from '../../../dto/workstation.dto';
import CellsService from '../../../services/cells.service';
import { parseError } from '../../../config/error-handler';
import NewWorkstationDto from '../../../dto/new-workstation.dto';
import { toast } from 'react-toastify';

const NewCellsPage = () => {

  const cellService = new CellsService();
  
  const [producao, setProducao] = useState("");
  const [meta, setMeta] = useState("");
  const [layout, setLayout] = useState<number>(1);
  const [selectedImage, setSelectedImage] = useState<string>(img1);

  const [stationCount, setStationCount] = useState<number>(0);

  const [isFullCardVisible, setIsFullCardVisible] = useState(false);
  const [selectedStationIndex, setSelectedStationIndex] = useState<number | null>(null);
  const [productionText, setProductionText] = useState("");
  const [metaCell, setMetaCell] = useState<string>("");
  const [workstations, setWorkstations] = useState<NewWorkstationDto[]>([]);

  const [testeWorkstations, setTesteWorkstations] = useState<NewWorkstationDto[]>([{
    goal: 0,
    orderOnCell: 0,
  }])

  const incrementStationCount = () => {
    if (!producao) {
      alert("Por favor, preencha o campo de produção antes de adicionar uma estação.");
      return;
    }
    
    const newStation = {
      goal: 0,
      orderOnCell: stationCount + 1,
    };

    setWorkstations(prevWorkstations => {
      return [...prevWorkstations, newStation];
    });

    setStationCount(count => count + 1);
  };

  function handleDelete(index: number){
    if (selectedStationIndex !== null) {
      setSelectedStationIndex(null);
    }
    
    setWorkstations(workstations.filter((_, i) => i !== index));

    setStationCount(count => count - 1);

  };

  const handleStationSelection = (index: number) => {
    setSelectedStationIndex(index === selectedStationIndex ? null : index);
    setIsFullCardVisible(true);
  };

  const handleProductionChange = (value: string) => {
    if (!/\d/.test(value)) {
      setProducao(value.toUpperCase());
    }
  };

  const handleImageClick = (image: string, number: number) => {
    setSelectedImage(image);
    setLayout(number);
  };

  const handleMetaCellChange = (value: string) => {
    if (!isNaN(Number(value))) {
      setMeta(value);
    }
  };

  const SaveCell = () => {
    //Exclui o objeto de start
    testeWorkstations.shift()

    if (!meta) {
      alert("Por favor preencha a meta da célula");
      return 
    }

    //Coloca o orderOnCell
    for(let i = 1; i <= workstations.length; i++ ){
      const ws : NewWorkstationDto = {
        goal: 0,
        orderOnCell: i
      }

      testeWorkstations.push(ws)
      }
      
      const cell: NewCellDto = {
        name: producao,
        goal: parseInt(meta),
        layout: layout,
        workstations: testeWorkstations,
      };
    
      cellService
        .createCell(cell)
        .then(history.goBack)
        .catch(parseError);
  };

  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <AuthenticatedLayout>
      <GoBackButton
        text="Criar Nova Célula"
        accentButton={
          <AccentButton
            onClick={SaveCell}
            title="Salvar"
            style={{ width: '140px' }}
          />
        }
      />
      <Row>
        <Styled.LeftColumn>
          <Styled.Card>
            <Row>
              <CreateCell labelText="PRODUÇÃO" onChange={handleProductionChange} value={producao} />
              <CreateCell labelText="META DA CÉLULA" onChange={handleMetaCellChange} value={meta} />
            </Row>
            <Styled.h1>LAYOUT DA CÉLULA</Styled.h1>
            <Row>
              <StyleCellsView
                labelText="Opção 1"
                image={img1}
                onClick={() => handleImageClick(img1, 1)}
                isSelected={selectedImage === img1}
              />
              <StyleCellsView
                labelText="Opção 2"
                image={img2}
                onClick={() => handleImageClick(img2, 2)}
                isSelected={selectedImage === img2}
              />
              <StyleCellsView
                labelText="Opção 3"
                image={img3}
                onClick={() => handleImageClick(img3, 3)}
                isSelected={selectedImage === img3}
              />
            </Row>
          </Styled.Card>
        </Styled.LeftColumn>
        <Styled.RightColumn >
          <Styled.Card >
            <Row style={{ justifyContent: 'space-between' }}>
              <Styled.h1>POSTO DE TRABALHO</Styled.h1>
              <AddStation onClick={incrementStationCount} isProductionFilled={!!producao} />
            </Row>
            {stationCount > 0 && (
              workstations.map((station, index) => (
                <FieldStation
                  key={index}
                  stationIndex={index + 1}
                  isSelected={selectedStationIndex === index + 1}
                  onSelect={() => handleStationSelection(index + 1)}
                  productionText={producao}
                  status={false}
                  handleDelete={() => handleDelete(index)}
                  stopReason=''
                />
              ))
            )}
          </Styled.Card>
        </Styled.RightColumn>
      </Row>
    </AuthenticatedLayout>
  );
};

export default NewCellsPage;