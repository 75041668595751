import ReactApexChart from "react-apexcharts";
import * as Styled from "./styled";
import CircularChartProps from "../../../models/circular-chart-props.model";
import { ApexOptions } from 'apexcharts';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const CircularChart = ({ series, size }: CircularChartProps) => (
  <Styled.ChartContainer>
    <ReactApexChart 
      options={buildOptions(size, series ? series[0] : 100)} 
      series={series} 
      type="radialBar" 
      height={buildDimension(size)}
      width={buildDimension(size)}
    />
  </Styled.ChartContainer>
)

export default CircularChart;


// ----------------------------------------------------------------------------
//         Functions
// ----------------------------------------------------------------------------
function buildOptions(size: 'small' | 'regular' | 'big', percent: any): ApexOptions {
  const textStyle = buildTextStyle(size);
  const color = buildColor(percent);

  return {
    chart: {
      height: 50,
      type: 'radialBar',
      sparkline: {
        enabled: true
      },
      foreColor: color,
    },
    colors: [color],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: buildChartStroke(size)
        },
        dataLabels: {
          name: {
            show: false
          },
          value: textStyle
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
  };
}

function buildColor(value: number) {
  if (value < 50) {
    return '#B43131';
  }

  if (value < 75) {
    return '#C35301';
  }

  if (value < 80) {
    return '#007B99';
  }

  return '#0F6A00';
}

function buildTextStyle(size: 'small' | 'regular' | 'big') {
  if (size === 'small') {
    return {
      offsetY: 0,
      fontSize: '0',
      fontWeight: '0'
    }
  }

  if (size === 'regular') {
    return {
      offsetY: 7,
      fontSize: '14px',
      fontWeight: '700'
    }
  }

  return {
    offsetY: 10,
    fontSize: '22px',
    fontWeight: '550'
  };
}

function buildChartStroke(size: 'small' | 'regular' | 'big') {
  if (size === 'small') {
    return '10%';
  }

  if (size === 'regular') {
    return '40%';
  }

  return '50%';
}

function buildDimension(size: 'small' | 'regular' | 'big') {
  if (size === 'small') {
    return 50;
  }

  if (size === 'regular') {
    return 100;
  }

  return 150;
}
