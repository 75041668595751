import RoutePath from "../models/route-path.model";
import CellsPage from "../pages/cells";
import EditCellsPage from "../pages/cells/edit";
import NewCellsPage from "../pages/cells/new";
import DashboardPage from "../pages/dashboard";
import UsersPage from "../pages/users";
import UsersPageEdit from "../pages/users/edit";
import UsersPageNew from "../pages/users/new";
import ViewGoals from "../pages/cells/viewGoals";
import RegisterGoals from "../pages/cells/registerGoals";


// ----------------------------------------------------------------------------
//         Constants
// ----------------------------------------------------------------------------
const protectedRoutes: RoutePath[] = [
  { path: '/', exact: true, component: DashboardPage },
  { path: '/dashboard', exact: true, component: DashboardPage },
  { path: '/cells', exact: true, component: CellsPage },
  { path: '/cells/new', exact: true, component: NewCellsPage},
  { path: '/cells/edit', exact: true, component: EditCellsPage},
  { path: '/users', exact: true, component: UsersPage},
  { path: '/users/new', exact: true, component: UsersPageNew},
  { path: '/users/edit', exact: true, component: UsersPageEdit},
  { path: '/cells/view-goals', exact: true, component: ViewGoals},
  { path: '/cells/register-goals', exact: true, component: RegisterGoals},
];

export default protectedRoutes;
