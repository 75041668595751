import styled from 'styled-components';

export const Container = styled.button<{ fgColor: string, bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 6px;
  border: 2px solid ${props => props.fgColor === '#FFFFFF' ? props.bgColor : props.fgColor};
  color: #FFFFFF;
  background-color: ${props => props.bgColor};
  margin-right: 10px;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  @font-face {
    font-family: 'Lato', sans-serif;
    url('../../../assets/fonts/Lato-Regular.ttf') format('truetype');
  }
`;

