import React from 'react';
import * as Styled from "./styled";

interface PopupProps {
  stationName: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const Popup: React.FC<PopupProps> = ({ stationName, onCancel, onConfirm }) => {
  return (
    <Styled.PopupOverlay>
      <Styled.PopupContainer>
        <Styled.PopupContent>
          <Styled.PopupMessage>
            Você tem certeza que deseja excluir o <Styled.StationName>{stationName}</Styled.StationName>?
          </Styled.PopupMessage>
          <Styled.ButtonContainer>
            <Styled.CancelButton onClick={onCancel}>Cancelar</Styled.CancelButton>
            <Styled.ConfirmButton onClick={onConfirm}>Confirmar</Styled.ConfirmButton>
          </Styled.ButtonContainer>
        </Styled.PopupContent>
      </Styled.PopupContainer>
    </Styled.PopupOverlay>
  );
};

export default Popup;
