import { toast } from "react-toastify";
import axios from "axios";
import Service from "./service";
import ProductionCellDto from "../dto/production-cell.dto";
import NewCellDto from "../dto/new-cell.dto";
import EditCellDto from "../dto/edit-cell.dto";
import WorkstationDto from "../dto/workstation.dto";
import EditWorkstationDto from "../dto/edit-workstation.dto";

class CellsService extends Service {
  
  constructor() {
    super();
  }

  async getProductions() : Promise<ProductionCellDto[]> {
    try {
      const response = await axios.get<ProductionCellDto[]>(`${process.env.REACT_APP_BASE_URL}cell/all`);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter Célula:', error);
      toast.error('Erro ao obter Célula');
      throw error;
    }
  }

  async getListWorkstation() : Promise<WorkstationDto[]> {
    try {
      const response = await axios.get<WorkstationDto[]>(`${process.env.REACT_APP_BASE_URL}cell/workstations`);
      return response.data;
    }catch (error) {
      console.error('Erro ao obter Estações:', error);
      toast.error('Erro ao obter Estações');
      throw error;
    }
  }

  async createCell(cell : NewCellDto) : Promise<any> {
    try{
      await this.remoteRequest.post<NewCellDto>(`${process.env.REACT_APP_BASE_URL}cell/` , cell)
      toast.success("Célula criada !!")
    } catch (error) {
      console.error("Erro ao criar a célula !!")
      toast.error("Erro ao criar a célula !!")
    }
  }

  async editCell(id : number, newCell : EditCellDto) : Promise<any>{
    try{
      await this.remoteRequest.put<NewCellDto>(`${process.env.REACT_APP_BASE_URL}cell/${id}`, newCell)
      toast.success("Célula modificada !!")
    }catch{
      console.error("Erro ao modificar célula !!")
      toast.error("Erro ao modificar célula !!")
    }
  }

  async editWorkstation(id: number, editWorkstation: EditWorkstationDto) : Promise<any>{
    try{
      await this.remoteRequest.put<EditWorkstationDto>(`${process.env.REACT_APP_BASE_URL}workstation/${id}`, editWorkstation)
      toast.success("Workstation modificada !!")
    }catch{
      console.log("Erro ao modificar a Workstation")
      toast.error("Erro ao modificar a Workstation")
    }
  }

  async deleteCell(id : number) : Promise<any>{
    try{
      await this.remoteRequest.delete<number>(`${process.env.REACT_APP_BASE_URL}cell/${id}`)
      toast.info("Célula " + id + " deletada !!")
    } catch (error){
      console.error("Erro ao deletar célula !!")
      toast.error("Erro ao deletar célula !!")
    }
  }

  async deleteWorkstation(id:number) : Promise<any>{
    try{
      await this.remoteRequest.delete<number>(`${process.env.REACT_APP_BASE_URL}workstation/${id}`)
      toast.info("Posto " + id + " deletada !!")
    }catch{
      console.error("Erro ao deletar Workstation !!")
      toast.error("Erro ao delete Workstation !!")
    }
  }

  
  
}

export default CellsService
