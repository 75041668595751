import styled from 'styled-components';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  flex: 1;
  background-color: #80764B;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
`;

export const HamburgerMenu = styled.button`
  border: 0;
  background: transparent;
`;

export const MenuLine = styled.div`
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownMenu = styled.div<{dropDownOpen : boolean}>`
  top:70px;
  display: ${props => props.dropDownOpen ? "flex" : "none"};
  position: absolute;

  width: 145px;
  flex-direction: column;
  z-index: 1;
  border: 1px solid black;
  border-radius:10px;
`

export const Account = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;

  color: black;
  background-color: gray;
  padding: 5px 10px;

  border-radius:10px 10px 0px 0px;
`

export const ExitButton = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;

  color: black;
  background-color: white;
  

  padding: 10px;
  border-radius:0px 0px 10px 10px;
  &:hover{
    cursor: pointer;
    text-decoration: underline;
  }
`
export const RowDoNavBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

export const RowDasCelulas = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;

  &:hover {
    ${DropdownMenu} {
      display: flex;
    }
  }
`;

export const UserProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
`;

export const UserPicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  img {
    height: 35px;
    border-radius: 50%;
  }
`;

export const Username = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: white;
`;

export const Role = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: white;
`;
