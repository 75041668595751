import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Styled from "./styled";

interface GoBackButtonProps {
  text: string;
  accentButton?: React.ReactNode; 
}

const GoBackButton: React.FC<GoBackButtonProps> = ({ text, accentButton }) => { 
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Styled.Container>
      <Styled.ContentContainer onClick={goBack}>
        <Styled.ArrowBackIosNew />
        <Styled.Text>{text}</Styled.Text>
      </Styled.ContentContainer>
      {accentButton && accentButton} 
    </Styled.Container>
  );
};

export default GoBackButton;
