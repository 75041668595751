import React from "react";
import BlockComponent from "../../models/block-component.model";
import * as Styled from "./styled";

interface RowProps extends BlockComponent {
  style?: React.CSSProperties;
}

const Row: React.FC<RowProps> = ({ children, style }) => {
  return (
    <Styled.Row style={style}>
      { children }
    </Styled.Row>
  );
};

export default Row;
