import styled from 'styled-components';


// Vars
const marrom = '#251F06'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  flex: 1;
  display: flex;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  z-index: 999999;
  background: rgba(0,0,0,0.7);
`;

export const Menu = styled.div`
  flex: 1;
  background-color: ${marrom};
  display: flex;
  flex-direction: column;
  padding: 0;
  position: absolute;
  height: 100vh;
  z-index: 9999;
  width: 250px;
`;

export const Header = styled.div`
  
`;

export const HamburgerMenu = styled.button`
  border: 0;
  background: transparent;
  padding-left: 30px;
  height: 80px;

  div {
    width: 35px;
    height: 5px;
    background-color: #666;
    margin: 6px 0;
  }

  div:nth-child(1) {
    transform: rotate(-45deg);
    margin-top: 10px;
  }

  div:nth-child(2) {
    background: transparent;
  }

  div:nth-child(3) {
    margin-top: -22px;
    transform: rotate(45deg);
  }
`;

export const MenuLine = styled.div`
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
`;

export const MenuItems = styled.ul`
  display: flex;
  flex-direction: column;  
  padding: 0;
  margin: 0;
  margin-top: 30px;
  height: 100%;

  li {
    display:flex;
    align-items: center;
    list-style-type: none;
    padding: 0 20px;
    height: 60px;

    font-size: 18px;
    font-weight: 400;
    font-family: var(--Lato-font);

    button {
      color: white;
      display:flex;
      justify-content: flex-start;
      gap: 0.625rem;
      background: transparent;
      border-radius: 0.5rem;
      align-items: center;
      border: 0;
      font-size: 0.850rem;
      width: 100%;
      height: 75%;
      padding: 0.5rem 0.6rem 0.5rem 1rem;
      cursor: pointer;

      &.active {
        background: #C7BB8B;
        color: black;
      }
      &:hover{
        background: #C7BB8B;
        color: black;
      }
    }
  
    &:nth-last-child(2){
      margin-top: auto;
    }
    &:last-child{
      margin-bottom: 2.5rem;
    }
  }
`;
