import { useEffect, useState } from "react";
import * as Styled from "./styled";
import AuthenticatedLayout from "../_layouts/Authenticated";
import CircularChart from "../../components/charts/CircularChart";
import ProductionEmployeeDto from "../../dto/production-employee-data.dto";
import ProductionTable from "../../components/ProductionTable";
import ProductionCellDto from "../../dto/production-cell.dto";
import ProductionCells from "../../components/ProductionCells";
import Field from "../../components/Field";
import GoalChart from "../../components/charts/GoalChart";
import DashboardService from "../../services/dashboard.service";
import { parseError } from "../../config/error-handler";
import OptionDto from "../../dto/option.dto";
import Header from "../../components/template/Header";
import Row from "../../components/Row";
import Column from "../../components/Column";
import PackagingDto from "../../dto/packaging.dto";
import { useHistory } from "react-router-dom";
import ProductionDto from "../../dto/production.dto";

// Constants
const dashboardService = new DashboardService();
const DEFAULT_CELL_ID = 1; // Defina o cellId padrão aqui


const DashboardPage = () => {
  const [cellData, setCellData] = useState<ProductionCellDto[]>([]);
  const [filter, setFilter] = useState('');
  const [view, setView] = useState('');
  const [productionEmployeesData, setProductionEmployeesData] = useState<ProductionEmployeeDto[]>([]);
  const [productions, setProductions] = useState<ProductionCellDto[]>([]);
  const [packaging, setPackaging] = useState<PackagingDto>({
    id: 0,
    production: 0,
    goal: 0,
    nc: 0,
    worked: 0,
    unavailability: 0
  });
  const [filterOptions, setFilterOptions] = useState<OptionDto[]>([]);
  const [viewOptions, setViewOptions] = useState<OptionDto[]>([]);
  const [tvInfos, setTvInfos] = useState<ProductionDto[]>([{
    name: "",
    amount: 0,
    meta: 0
  }])

  useEffect(() => {
    dashboardService
      .getProductions()
      .then(data => setCellData(data))
      .catch(parseError);

    const userId = 1

    dashboardService
      .getProductionsByEmployee(userId)
      .then(setProductionEmployeesData)
      .catch(parseError);

      dashboardService
      .getAllCells()
      .then(cells => {
        let totalMeta = 0; 
        let totalProduction = 0;
        let totalNC = 0;
        let totalWorkedHours = 0;

        cells.forEach(cell => {
            cell?.workstations?.forEach(workstation => {
                totalMeta += workstation.goal
                totalProduction += workstation.unitsProduced
                totalNC += workstation.unitsProducedNc
            });
          }
        )
        setPackaging(prevPackaging => ({
          ...prevPackaging,
          production: totalProduction,
          goal: totalMeta, // Defina um valor padrão se goal for undefined
          nc: totalNC,
          id: 0, // Inclua o id do cell
          worked: 0, // Defina um valor inicial para worked
          unavailability: 0 // Defina um valor inicial para unavailability
        }));
      })
      .catch(parseError);
    

    dashboardService
      .getViewOptions()
      .then(setViewOptions)
      .catch(parseError);

    dashboardService
      .getFilterOptions()
      .then(setFilterOptions)
      .catch(parseError);   

      dashboardService
      .getTvData(getStartWeek())
      .then(setTvInfos)
      .catch(parseError)
  }, [])

  function getStartDay(){
    const startDay : Date = new Date()
    startDay.setHours(0,1,0,0)
    return startDay
  }

  function getStartWeek(){
    const startWeek : Date = new Date()

    const diaDaSemana = startWeek.getDate()
    const diasParaVoltar = (1 - diaDaSemana) * -1

    startWeek.setDate(startWeek.getDate() - diasParaVoltar )
    
    // 0 - Domingo
    // 1 - Segunda
    // 2 - Terça
    // 3 - Quarta
    // 4 - Quinta
    // 5 - Sexta
    // 6 - Sabado
    
    // ( NUMERO DE SEGUNDA ) - ( NUMERO DO DIA DA SEMANA ) = ( QUANTOS DIAS PRECISA VOLTAR )
    
    startWeek.setHours(0,1,0,0)

    return startWeek
  }

  function getStartMonth(){
    const startMonth : Date = new Date()
    
    startMonth.setDate(1)
    startMonth.setHours(0,1,0,0)
  }

  //---------------------------
  //  Botões "mostrar"
  //---------------------------

  const history = useHistory();

  const goCellsPage = () => {
    history.push("/cells")
  }

  const goUsersPage = () => {
    history.push("/users")
  }

  return (
    <AuthenticatedLayout>
      <Header title='Dashboard'>
        <Row>
          <Styled.Filter>
            <Styled.InputDefault
              size="small"
              select
              value={filter}
              onChange={event => setFilter(event.target.value)}
              SelectProps={{
                native: true,
              }}
            >
              {filterOptions.map((option: OptionDto, index) => (
                <option key={option.id ?? index} value={option.id}>
                  {option.label}
                </option>
              ))}
            </Styled.InputDefault>
          </Styled.Filter>
          
          <Styled.Filter>
            <Styled.InputDefault
              highlight={true}
              size="small"
              select
              value={view}
              onChange={event => setView(event.target.value)}
              SelectProps={{
                native: true,
              }}
            >
              {viewOptions.map((option: OptionDto, index) => (
                <option key={option.id ?? index} value={option.id} style={{color:"black"}}>
                  {option.label}
                </option>
              ))}
            </Styled.InputDefault>
          </Styled.Filter>
        </Row>
      </Header>

      <Row>
        <Styled.LeftColumn>
          <Styled.Card>
            <h2>Geral / empacotamento</h2>
            <Row>
              <Styled.Fields>
                <Column>
                  <Field label="Unidades produzidas" value={packaging?.production?.toString()} />
                  <Field label="Meta" value={packaging?.goal?.toString()} />
                </Column>
              </Styled.Fields>
              <Styled.ChartContainer>
                {packaging &&
                  <CircularChart
                    series={[parseFloat(((packaging?.production ?? 0) / (packaging?.goal ?? 1) * 100).toFixed(1))]}
                    size='big'
                  />
                }
              </Styled.ChartContainer>
            </Row>
            <Row>
              <Styled.Fields>
                <Row>
                  <Field label="Unidades N/C" value={packaging?.nc?.toString()} />
                  <Field label="Trabalhadas" value={`${Math.floor(packaging?.worked / 100).toString().padStart(2, '0')}h`} /> 
                  {/*<Field label="Trabalhadas" value={packaging?.worked.toString()} />*/}
                  <Field label="Indisponibilidade" value={`${Math.floor(packaging?.unavailability / 100).toString().padStart(2, '0')}h`} />
                </Row>
              </Styled.Fields>
            </Row>


          </Styled.Card>

          <Styled.FullCard>
            <h2>Células de produção</h2>
            <ProductionCells data={cellData} />
            <Styled.LinkButton onClick={goCellsPage}>
              Mostrar detalhes das células
            </Styled.LinkButton>
          </Styled.FullCard>
        </Styled.LeftColumn>

        <Styled.RightColumn>
          <Styled.Card>
            <h2>Volume de produção por funcionário</h2>
            <Styled.TableContainer>
              <ProductionTable data={productionEmployeesData} />
            </Styled.TableContainer>
            <Row>
              <Styled.LinkButton onClick={goUsersPage}>
                Mostrar todos funcionários
              </Styled.LinkButton>
            </Row>
          </Styled.Card>
          <Styled.FullCard>
            <h2>Acompanhamento instantâneo da Meta</h2>
            <GoalChart infos={tvInfos}/>
          </Styled.FullCard>

        </Styled.RightColumn>
      </Row>
    </AuthenticatedLayout>
  );
};

export default DashboardPage;
