import styled from 'styled-components';

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupContainer = styled.div`
  display: flex;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 20px;
  width: 570px;
  height: 295px;
  align-items: center;
  justify-content: space-around;
`;

export const PopupContent = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 59%;
  flex-direction: column;
`;

export const PopupMessage = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

export const StationName = styled.strong`
  font-weight: bold;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CancelButton = styled.button`
  background-color: #FFFFFF;
  color: #80764B;
  font-weight: 700;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  border-color: #80764B;
  padding: 8px 16px;
  cursor: pointer;
  width: 165px;
  height: 44px;
`;

export const ConfirmButton = styled.button`
background-color: #80764B;
color: #FFFFFF;
font-weight: 700;
font-size: 16px;
border: 1px solid #ccc;
border-radius: 8px;
border-color: #80764B;
padding: 8px 16px;
margin-right: 10px;
cursor: pointer;
width: 165px;
height: 44px;
`;

