import styled from "styled-components"

export const PopUpOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PopUp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  width: 570px;
  padding: 10px;

  h1{
    line-height: 30px;
    font-weight: 400;
    font-size: 20px;
    width: 100%;
    text-align: left;
    margin-bottom: 22px;
  }
`

export const showWork = styled.div`
  width: 510px;
  background-color: #f7f7f7;
  margin-bottom: 50px;
  
  h2{
    line-height: 32px;
    font-weight: 700;
    font-size: 16px;
    margin: 0px;
    width: 100%;
    text-align: center;
    color: white;
    background-color: #454d5f;
    border-radius: 8px 8px 0px 0px;
  }
`

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  label{
    color: #4B4B4B;
    font-weight: 400;
    line-height: 32px;
    font-size: 16px;
    margin:8px 0px;
  }

  input{
    outline: none;
    border-radius: 8px;
    border: 1px solid #454d5f;
    margin-bottom: 17px;

    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    width: 125px;
    text-align: center;
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 510px;
  margin-bottom: 21px;
  justify-content: space-between;
`

export const CancelButton = styled.button`
  background-color: #fff;
  color: #80764b;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #80764B;
  border-radius: 8px;
  padding: 8px 16px;
  width: 154px;
  height: 44px;
  cursor: pointer;
`

export const SaveButton = styled.button`
  background-color: #80764b;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #80764b;
  border-radius: 8px;
  padding-block-end: 8px 16px;
  margin-right: 10px;
  width: 154px;
  height: 44px;
  cursor: pointer;
`

export const Strong = styled.strong`
  font-weight: 700;
`
