import AuthenticatedLayout from "../../_layouts/Authenticated";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GoBackButton from "../../../components/buttons/GoBack";
import AccentButton from "../../../components/buttons/AccentButton";
import * as Styled from "./styled";
import { Button } from "@mui/material";
import UserDto from "../../../dto/user.dto";
import UserService from "../../../services/user.service";
import { parseError } from "../../../config/error-handler";
import NewUserDto from "../../../dto/new-user.dto";
import { toast } from "react-toastify";

interface LocationState {
  user: UserDto;
  onDelete?: () => void; 
}

interface UsersPageEditProps {
  handleDeleteButtonClick: (user: UserDto) => void;
}

const UsersPageEdit: React.FC<UsersPageEditProps> = ({ handleDeleteButtonClick }) => {
  const userService = new UserService()

  const [user, setUser] = useState<UserDto>({
    id: 0,
    name: "",
    email: "",
    phone: "",
    workstation: "",
    shift: "",
    password: "",
    picture: "",
    role: 0
  });

  const [newUser, setNewUser] = useState<NewUserDto>({
    name: "",
    email: "",
    phone: "",
    workstation: "",
    shift: "",
    password: "",
    picture: ""
  })

  const [formData, setFormData] = useState<FormData>(new FormData());

  const [displayImg, setDisplayImg] = useState("")

  const history = useHistory();
  const { state } = useLocation<LocationState>();

  useEffect(() => {
    if (state && state.user) {
      setUser(state.user)
      setNewUser(state.user);
    }
  }, [state]);

  //---------------------------
  //  Handlers
  //---------------------------

  const changeUser = () => {

    const validation = 
    newUser.name !== "" 
    && newUser.email !== "" 
    && newUser.phone !== ""  
    && newUser.workstation !== ""  
    && newUser.shift !== ""  
    && newUser.password !== "" ;

    if(!validation){
      toast.error("Preencha todos os dados !!")
    }else{
      if(formData == null){
        const imageFormData = new FormData();
        imageFormData.append('file', "");
  
        setFormData(imageFormData)
      }
  
      const blob = new Blob([JSON.stringify(newUser)], { 
        type: "application/json"
      })
  
      formData.append('user', blob)
      formData.append('oldUser', user.picture)
       
      userService
      .changeUser(user.id, formData)
      .then(history.goBack)
      .catch(parseError)
    }
  };

  const handleEditPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageUrl = reader.result as string;
        setDisplayImg(imageUrl);
      };
      reader.readAsDataURL(file);

      const imageFormData = new FormData();
      imageFormData.append('file', file);

      setFormData(imageFormData)
      
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewUser(prevUser => ({ ...prevUser, [name]: value }));
  };

  const handleShiftSelection = (e : React.ChangeEvent<HTMLSelectElement>) => {
    const shift = e.target.value

    setNewUser({...newUser, shift: shift  })  
  }

  return (
    <AuthenticatedLayout>
      <GoBackButton
        text="Editar usuário"
        accentButton={
          <AccentButton
            onClick={changeUser}
            title="Salvar"
            style={{ width: '140px' }}
          />
        }
      />

      <Styled.Card>
        <Styled.Column>
          <Styled.Button>
            <Styled.UserAvatar>
              {displayImg ? <img src={displayImg} alt="Avatar" /> : <AddCircleOutlineIcon />}
            </Styled.UserAvatar>
            <Styled.Subtitle>
              <label htmlFor="avatar-upload">Editar foto</label>
              <input
                id="avatar-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleEditPhoto}
              />
            </Styled.Subtitle>
          </Styled.Button>
        </Styled.Column>


        <Styled.Column>
          <Styled.InputContainer>
            <Styled.Label>Nome de usuário</Styled.Label>
            <Styled.Input name="name" width="427px" value={newUser.name} onChange={handleChange} />
          </Styled.InputContainer>
          <Styled.InputContainer>
            <Styled.Label>Cargo</Styled.Label>
            <Styled.Input name="workstation" width="427px" value={newUser.workstation} onChange={handleChange} />
          </Styled.InputContainer>
          <Styled.Row>
            <Styled.InputContainer>
              <Styled.Label>Senha</Styled.Label>
              <Styled.Input type="password" name="password" width="194px" value={newUser.password} onChange={handleChange} />
            </Styled.InputContainer>
          </Styled.Row>
        </Styled.Column>
        <Styled.Column>
          <Styled.InputContainer>
            <Styled.Label>E-mail</Styled.Label>
            <Styled.Input name="email" width="307.91px" value={newUser.email} onChange={handleChange} />
          </Styled.InputContainer>
          <Styled.InputContainer>
            <Styled.Label>Turno Atual</Styled.Label>
            <Styled.Select name="shiftSelection" onChange={handleShiftSelection}>
              <option value={newUser.shift} selected hidden>{newUser.shift}</option>
              <option value="Manha">Manhã</option>
              <option value="Tarde">Tarde</option>
              <option value="Noite">Noite</option>
            </Styled.Select>
            {/* <Styled.Input name="shift" width="307.91px" value={newUser.shift} onChange={handleChange} /> */}
          </Styled.InputContainer>
        </Styled.Column>
        <Styled.Column>
          <Styled.InputContainer>
            <Styled.Label>Telefone</Styled.Label>
            <Styled.Input name="phone" width="193px" value={newUser.phone} onChange={handleChange} />
          </Styled.InputContainer>
        </Styled.Column>
      </Styled.Card>
    </AuthenticatedLayout>
  );
}

export default UsersPageEdit;
