import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TooltipText = styled.p`
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  @font-face {
    font-family: 'Lato', sans-serif;
    url('../../assets/fonts/Lato-Regular.ttf') format('truetype');
  }
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 5px;
`;
