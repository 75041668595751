import { useEffect, useState } from "react";
import * as Styled from "./styled";
import TopTooltip from "../TopTooltip";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const NewStation = ({ number, selected, stopReason, onClick }: any) => {

  const [bgColor, setBgColor] = useState('');
  const [fgColor, setFgColor] = useState('');
  const [tooltipText, setTooltipText] = useState('')

  useEffect(() => {
    setBgColor(buildBgColor(selected, stopReason));
    setTooltipText(stopReason)
  }, [selected, stopReason]);

  return (
    <TopTooltip title={`Motivo de parada: ${stopReason}`} display={!!stopReason} placement="top">
      <Styled.Container 
        bgColor={bgColor} 
        fgColor={fgColor} 
        onClick={onClick}
      >
        { number }
      </Styled.Container>
    </TopTooltip>
  );
};

export default NewStation;


// ----------------------------------------------------------------------------
//         Functions
// ----------------------------------------------------------------------------
function buildBgColor(selected?: boolean, stopReason?: string) {
  if (selected) {
    return '#80764B';
  }

  if (stopReason) {
    return '#B43131';
  }

  return '#191D27';
}


