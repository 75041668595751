import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export const NameTitle = styled.h1`
  color: white;
  padding: 5px;
`;

export const Columns = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const LeftColumn = styled.div`
  flex: 2;
  background: #888;
  display: flex;
  flex-direction: column;
  background-image: url('/aroma.jpeg');
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
`;

export const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
`;

export const Header = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Title = styled.h2`
  text-align: center;
  margin-top: 100px;
`;

export const Form = styled.div`
  flex: 1;
`;
