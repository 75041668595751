import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
`;

export const RadialChart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
