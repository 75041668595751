import * as Styled from "./styled";
import { ApexOptions } from 'apexcharts';
import ProgressBarProps from '../../models/progress-bar-props.model';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const ProgressBar = ({ percent }: ProgressBarProps) => {

  const color = buildColor(percent);
  
  return (
    <Styled.ProgressBar color={color}>
      <Styled.ProgressBarWrapper>
        <Styled.Progress percent={percent} color={color} />
      </Styled.ProgressBarWrapper>
      <span>
        {percent.toFixed(2)}%
      </span>
    </Styled.ProgressBar>
  );
}

export default ProgressBar;


// ----------------------------------------------------------------------------
//         Functions
// ----------------------------------------------------------------------------
function buildColor(value: number) {
  if (value < 50) {
    return '#B43131';
  }

  if (value < 75) {
    return '#C35301';
  }

  if (value < 80) {
    return '#007B99';
  }

  return '#0F6A00';
}
