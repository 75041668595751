import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
