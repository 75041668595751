import React from "react";

interface svgProps{
  height?: number;
  width?: number;
  color?: string;
}

const DeleteSvg = ({width=21, height=21, color='#B43131'}: svgProps) => {
  return(
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2035_833)">
      <path d="M13.5085 7.5V15.8333H6.8418V7.5H13.5085ZM12.2585 2.5H8.0918L7.25846 3.33333H4.3418V5H16.0085V3.33333H13.0918L12.2585 2.5ZM15.1751 5.83333H5.17513V15.8333C5.17513 16.75 5.92513 17.5 6.8418 17.5H13.5085C14.4251 17.5 15.1751 16.75 15.1751 15.8333V5.83333Z" fill={color}/>
      </g>
      <defs>
      <clipPath id="clip0_2035_833">
      <rect width="20" height="20" fill="white" transform="translate(0.175781)"/>
      </clipPath>
      </defs>
    </svg>
  );
}


export default DeleteSvg;