import Button from "@mui/material/Button";
import { buildWidth } from './style';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const AbstractButton = ({ children, onClick, type, style }: any) => (
  <Button
    style={{ ...buildWidth(type), ...style }}
    onClick={onClick}
    variant="contained"
  >
    { children }
  </Button>
);

export default AbstractButton;
