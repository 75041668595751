import styled from 'styled-components';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 18px;
  background-color: #EAECF2; 
  border-radius: 10px; 
  margin-right: 20px;
`;

export const Progress = styled.div<{ percent: number }>`
  width: ${props => props.percent}%;
  height: 80%;
  align-items: center;
  background-color: ${(props) => props.color}; 
  border-radius: 10px;
  transition: width 0.5s;
  margin: 2px;
`;

export const ProgressText = styled.span`
  width: 100%;
  display: flex;
  justify-content: end; 
  align-items: center;
`;

export const ProgressBar = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  color:  ${(props) => props.color}; Cor do texto que indica a porcentagem atual */ 
  @font-face {
    font-family: 'Lato', sans-serif;
    src: url('../../assets/fonts/Lato-Regular.ttf') format('truetype');
  }

  span {
    font-size: 14px;
    color: ${(props) => props.color};
    font-weight: 700;
    font-family: 'Lato';
  }
`;
  
