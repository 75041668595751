import { toast } from "react-toastify";
import Service from "./service";
import UserDto from "../dto/user.dto";


/**
 * Responsible for managing authentication requests. 
 */
class AuthService extends Service {
  
  // --------------------------------------------------------------------------
  //         Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }


  // --------------------------------------------------------------------------
  //         Methods
  // --------------------------------------------------------------------------

  
  public async makeLogin(email: string, password: string): Promise<boolean> {
    let success = false;
    
    try {
        const response = await this.remoteRequest.post('/login', {
            email,
            password
        });

        if (response.headers.authorization) {
            localStorage.setItem('x_auth_token', response.headers.authorization.substring(7));
            success = true;
        }
    } 
    catch (error: any) {
        toast.error(error.response.data.message)
    }

    return success;
  }

  public async makeLogout(): Promise<void> {
    localStorage.clear();
  }

  public async getAuthenticatedUser(): Promise<UserDto> {
    try{
      const response = await this.remoteRequest.get(`${process.env.REACT_APP_BASE_URL}auth/`);
      return response.data;
    }catch(e){
      console.log("Erro ao pegar o usuário autenticado !!")
      toast.error("Erro ao pegar o usuário autenticado !!")
      throw e;
    }

  }
}

export default AuthService;
