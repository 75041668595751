import styled from "styled-components";
import { TextField } from '@mui/material';


export const ContentCellList = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  height: auto;
  min-height: 70vh;
`;

export const ContainerChildrenHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
`;

export const ButtonDeleteAll = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
`;

export const Filter = styled.div`
  margin-left: 20px;
`;

export const InputDefault = styled(TextField)<{ highlight?: boolean }>`
  width: 100%;

  & .MuiInputBase-root {
    border-width: 1px;
    border: 1px solid rgba(72,46,25,0.5);
    border-radius: 8px;
    color: ${props => props.highlight ? 'white' : 'inherit'};
    background: ${props => props.highlight ? '#554C25' : 'inherit'};
  }

  & .MuiSvgIcon-root {
    color: ${props => props.highlight ? 'white' : 'inherit'};
  }
`;