import styled from 'styled-components';

export const ChartContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  height: 100%;
  
  canvas {
    flex-shrink: 1;
    flex-basis: 1;
    background-color: transparent;
    width: 100% !important;
  } 
`;

export const ContainerRow = styled.div<{distanciaP : number}>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media(width < 1080px){
    div{
      position: relative;
      width: 100%;
      border-top: 3px solid #454D5F;
      bottom: 50px;
    }
  }

  @media(width > 1080px){
    div{
    position: relative;
    width: 100%;
    border-top: 3px solid #454D5F;
    bottom: 31px;
    }
  }
`;