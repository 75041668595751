import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  cursor: pointer;
`;

export const Check = styled.div`
  border-radius: 4px;
  border: 1px solid #939393;
  background-color: #fff;
  width: 24px;
  height: 24px;
  margin-right: 2px;
`;


export const Checked = styled.div`
  border-radius: 4px;
  width: 24px;
  height: 24px;
  margin-right: 2px;
  background-color: #111 ;
  text-align: center ;
  color: #fff;
  font-size: 14px;
  padding-top: 2px ;
  font-weight: 500;
`;