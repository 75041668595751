import { toast } from "react-toastify";
import UserDto from "../dto/user.dto";
import Service from "./service";
import axios from "axios";
import NewUserDto from "../dto/new-user.dto";
import ProductionEmployeeDto from "../dto/production-employee-data.dto";

class UserService extends Service{

  constructor(){
    super()
  }

  async createUser(formData : FormData) : Promise<any>{
    try{
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        }, 
      };

      await this.remoteRequest.post<FormData>(`${process.env.REACT_APP_BASE_URL}user/` ,formData, config)

      console.log("Usuário criado com sucesso !!")
      toast.success("Usuário criado com sucesso !!")
    }catch(error){
      console.error("Erro ao criar o usuário !! ")
      toast.error("Erro ao criar o usuário !! ")
    }
  }

  async changeUser(id : number, formData : FormData) : Promise<any>{
    try {
      const config = {
        headers: {
          'content-type' : 'multipart/form-data',
        },
      };

      await this.remoteRequest.putForm<NewUserDto, number>(`${process.env.REACT_APP_BASE_URL}user/${id}`, formData)
      toast.success("Usuário Modificado !!", {autoClose: 1000})
    } catch (error) {
      console.error("Erro ao modificar usuário")
      toast.error("Erro ao modificar usuário")
    }
  }

  async deleteUser(id : number | undefined) : Promise<any>{
    try {
      await this.remoteRequest.delete<UserDto>(`${process.env.REACT_APP_BASE_URL}user/${id}`)
    } catch (error) {
      console.error("Erro ao excluir usuário")
      toast.error("Erro ao excluir usuário")
    }
  }

  async getAllUsers(): Promise<UserDto[]> {
    try {
      const response = await this.remoteRequest.get<UserDto[]>(`${process.env.REACT_APP_BASE_URL}user/all`);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter todos os usuários:', error);
      toast.error('Erro ao obter todos os usuários');
      throw error;
    }
  }
}

export default UserService