import styled from 'styled-components';

export const Card = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: white;
  padding: 15px 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  h2 {
    color: #454D5F;
    font-size: 16px;
    weight: 500;
    text-transform: uppercase;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaecf2;
  }
`;


export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 465px;
  margin-right: 15px;
`;

export const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  max-height: 807.36px;
`;

export const Right = styled.div`
  flex: 1;
`;

export const Left = styled.div`
  flex: 1;
`;

export const Form = styled.div`
  background-color: #fff;
  padding: 35px;
  margin: 20px 0px;
  padding: 0px 30px 0px 0px;
  margin: 20px 0;
`;

export const h1 = styled.h1`
  font-size: 17px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 0px 0px 10px;
  margin-bottom: 10px;
`;

export const FullCard = styled.div`
  background-color: white;
  padding: 15px 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const Stations = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 50px 0;

  @media (max-width: 767px) {
    flex-wrap: wrap;

    button {
      margin-bottom: 5px;
    }
  }
`;

export const Summary = styled.div`
  border-top: 1px solid #EAECF2;
  padding-top: 20px;
  position: relative;
  table {
    tr {
      color: #554C25;
      font-size: 16px;
      
      th {
        text-align: left;
        padding: 10px 0;
        padding-right: 50px;
        font-weight: 700;
      }

      td {

      }
    }
  }
`;

export const ProgressBarContainer = styled.div`
  p {
    font-weight: 400;
    color: #554C25;
    font-size: 16px;

  }
`;


