
import { buttonStyle } from './style';
import MaterialIcon from '../template/MaterialIcon';
import AbstractButton from '../template/AbstractButton';
import Button from '../../../models/button.model';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const AccentButton = ({ children, title, onClick, type, style }: Button) => (
  <AbstractButton
    style={{ ...buttonStyle, ...style }}
    type={type}
    onClick={onClick}
  >
    {children}
    <>{title}</>
  </AbstractButton>
);

export default AccentButton;