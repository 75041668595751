import React from "react";
import * as Styled from "./styled";

const Logo = () => (
  <Styled.LogoContainer>
    <img src="/viaaroma.svg" />
  </Styled.LogoContainer>
);

export default Logo;
