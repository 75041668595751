import * as Styled from "./styled";
import ProductionCellDto from "../../dto/production-cell.dto";
import ProductionCell from "../ProductionCell";

const ProductionCells = ({ data }: { data: ProductionCellDto[] }) => (
  <Styled.Container>
    {data.map((item) => (
      <ProductionCell 
        key={item.id} // Usando o id da sessão como chave única
        title={item.title} 
        goal={item.goal} 
        productionType={item.productionType}
        production={item.production} 
        nc={item.nc} 
        id={item.id}
        workstations={item.workstations ?? []} 
        workedHours={item.workedHours}
      />
    ))}
  </Styled.Container>
);

export default ProductionCells;
