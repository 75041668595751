import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  height: 100vh;
  background: #eee;
  overflow-y: scroll;
`;

export const Header = styled.div`
`;

export const Body = styled.div`
  padding: 10px 30px;
  height: calc(100vh - 100px);
`;
