import BlockComponent from "../../models/block-component.model";
import * as Styled from "./styled";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const Column = ({ children }: BlockComponent) => {

  return (
    <Styled.Column>
      { children }
    </Styled.Column>
  );
};

export default Column;
