// ----------------------------------------------------------------------------
//         Constants
// ----------------------------------------------------------------------------
const width = {
  small: '50px',
  big: '100%'
}

export function buildWidth(type: string) {
  if (width[type as keyof typeof width] === undefined) {
    return null;
  }

  return { width: width[type as keyof typeof width] };
}
