  import React, { useEffect, useState } from 'react';
  import AuthenticatedLayout from "../../_layouts/Authenticated";
  import GoBackButton from "../../../components/buttons/GoBack";
  import AccentButton from "../../../components/buttons/AccentButton";
  import * as Styled from "./styled";
  import { Row } from '../../../components/Row/styled';
  import CreateCell from '../../../components/forms/createCell';
  import StyleCellsView from '../../../components/buttons/StyleView';
  import AddStation from '../../../components/buttons/AddStation';
  import FieldStation from '../../../components/FieldStation';
  import Summary from '../../../components/Summary';
  import { useLocation, useHistory } from 'react-router-dom';
  import ProgressBar from "../../../components/ProgressBar";

  import img1 from './imgs/Rectangle 2428.png';
  import img2 from './imgs/Rectangle 2429.png';
  import img3 from './imgs/Rectangle 2420.png';
  import ButtonStations from '../../../models/button-stations.model';
import ProductionCellDto from '../../../dto/production-cell.dto';
import WorkstationDto from '../../../dto/workstation.dto';
import CellsService from '../../../services/cells.service';
import { parseError } from '../../../config/error-handler';
import NewCellDto from '../../../dto/new-cell.dto';
import { toast } from 'react-toastify';
import NewWorkstationDto from '../../../dto/new-workstation.dto';
import EditCellDto from '../../../dto/edit-cell.dto';
import EditWorkstationDto from '../../../dto/edit-workstation.dto';

  interface LocationState {
    name: string;
    producao: string;
    meta: string;
    workstations: WorkstationDto[];

    cellData : ProductionCellDto
  }

  const EditCellsPage = () => {
    const cellService = new CellsService()

    //Id da celula
    const [id, setId] = useState<number>(0)

    //Producao da celula
    const [producao, setProducao] = useState("");

    //Meta da celula
    const [meta, setMeta] = useState("");

    //Layout da celula
    const [layout, setLayout] = useState<number>(1);
    
    //Workstations da celula
    const [workstations, setWorkstations] = useState<EditWorkstationDto[]>([{
      id: 0,
      goal: 0,
      orderOnCell: 0,
    }])

    const [testeWorkstations, setTesteWorkstations] = useState<EditWorkstationDto[]>([{
      id: null,
      goal: 0,
      orderOnCell: 0
    }])

    const [workstationsExclude, setWorkstationsExclude] = useState<EditWorkstationDto[]>([{
      id: null,
      goal: 0,
      orderOnCell: 0
    }])
    
    const [stationCount, setStationCount] = useState<number>(workstations.length);
    
    const [selectedStationIndex, setSelectedStationIndex] = useState<number | null>(null);

    const location = useLocation<LocationState>();
    const history = useHistory();

    useEffect(() => {
      const editCellData = sessionStorage.getItem('editCellData');
      if (editCellData) {
        const {id ,producao, meta, workstations } = JSON.parse(editCellData);
        setId(id);
        setProducao(producao);
        setMeta(meta);

        workstations.forEach((element : WorkstationDto) => {
          const work : EditWorkstationDto = {
            id: element.id,
            goal: 0,
            orderOnCell: element.orderOnCell
          }
          setWorkstations(workstations)
        });

        setWorkstations([...workstations])
        setStationCount(workstations.length)
        sessionStorage.removeItem('editCellData');
      }
    }, []);

    const handleStationSelection = (index: number) => {
      setSelectedStationIndex(index === selectedStationIndex ? null : index);
    };

    //-------------------------------------------
    //  Adiciona workstation
    //-------------------------------------------

    const incrementStationCount = () => {
      if (!producao) {
        alert("Por favor, preencha o campo de produção antes de adicionar uma estação.");
        return;
      }
      
    setStationCount(count => count + 1);

    const newStation = {
      id: null,
      goal: 0,
      orderOnCell: 0,
    };


    setWorkstations([...workstations, newStation])

    };

    //------------------------------------
    //  Célula changes
    //------------------------------------

    const handleProductionChange = (value: string) => {
      if (!/\d/.test(value)) {
        setProducao(value.toUpperCase());
      }
    };
    

    const handleMetaCellChange = (value: string) => {
      if (!isNaN(Number(value))) {
        setMeta(value);
      }
    };

    const handleImageClick = (image: number) => {
      setLayout(image);
    };

    //--------------------------------
    //  Deleta cell
    //--------------------------------

    const handleDelete = (index : number) => {
      if (selectedStationIndex !== null) {
        setSelectedStationIndex(null); 
      }
    
      workstationsExclude.push(workstations[index])

      setWorkstations(workstations.filter((_,i) => i  !== index));

      setStationCount(count => count - 1);
    };

    //--------------------------------
    //  Salva célula
    //--------------------------------

    const saveCell = () => {
      workstationsExclude.shift()

      if (!meta) {
        alert("Por favor preencha a meta da célula");
        return
      }

      let i = 1

      workstations.forEach((element : EditWorkstationDto) => {
        const work : EditWorkstationDto = {
          id: element.id,
          goal: element.goal,
          orderOnCell: i,
        };

        testeWorkstations.push(work)

        i ++
      })

        testeWorkstations.shift()
        
        const cell : EditCellDto = {
          name: producao,
          goal: parseInt(meta),
          layout: layout,
          workstationsSave: testeWorkstations,
          workstationsExclude: workstationsExclude
        };
      
        cellService
          .editCell(id ,cell)
          .then(history.goBack)
          .catch(parseError);
    }

    const { pathname } = useLocation();
    return (
      <AuthenticatedLayout>
        <GoBackButton
          text="Editar Célula"
          accentButton={
            <AccentButton
              onClick={saveCell}
              title="Salvar"
              style={{ width: '140px' }}
            />
          }
        />
        <Row>
          <Styled.LeftColumn>
            <Styled.Card>
              <Row>
                <CreateCell labelText="PRODUÇÃO" onChange={handleProductionChange} value={producao} />
                <CreateCell labelText="META DA CÉLULA" onChange={handleMetaCellChange} value={meta} />
              </Row>
              <Styled.h1>LAYOUT DA CÉLULA</Styled.h1>
              <Row>
                <StyleCellsView
                  labelText="Opção 1"
                  image={img1}
                  onClick={() => handleImageClick(1)}
                  isSelected={layout === 1}
                />
                <StyleCellsView
                  labelText="Opção 2"
                  image={img2}
                  onClick={() => handleImageClick(2)}
                  isSelected={layout === 2}
                />
                <StyleCellsView
                  labelText="Opção 3"
                  image={img3}
                  onClick={() => handleImageClick(3)}
                  isSelected={layout === 3}
                />
              </Row>
            </Styled.Card>
            <Styled.FullCard>
              <Summary
                selectedStationIndex={selectedStationIndex}
                meta={selectedStationIndex !== null ? ButtonStations[selectedStationIndex]?.goal : meta}
                productionTotal={selectedStationIndex !== null ? ButtonStations[selectedStationIndex]?.totalProduction : '250'}
                productionNC={selectedStationIndex !== null ? ButtonStations[selectedStationIndex]?.production : '2'}
                workedHours={selectedStationIndex !== null ? ButtonStations[selectedStationIndex]?.workedHours : '7h45'}
                unavailability={selectedStationIndex !== null ? ButtonStations[selectedStationIndex]?.unavailability : '0h10'}
              />
            </Styled.FullCard>
          </Styled.LeftColumn>
          <Styled.RightColumn >
            <Styled.Card >
              <Row style={{ justifyContent: 'space-between' }}>
                <Styled.h1>POSTO DE TRABALHO</Styled.h1>
                <AddStation onClick={incrementStationCount} isProductionFilled={!!producao} />
              </Row>
              {workstations.map((_, index) => (
                <FieldStation
                  key={index}
                  stationIndex={index + 1}
                  isSelected={selectedStationIndex === index}
                  onSelect={() => handleStationSelection(index)}
                  stationName={`${index + 1}`}
                  productionText={producao}
                  status={true}
                  handleDelete={() => handleDelete(index)}
                  stopReason={ButtonStations[index]?.active}
                />
              ))}
            </Styled.Card>
          </Styled.RightColumn>
        </Row>
      </AuthenticatedLayout>
    );
  };

  export default EditCellsPage;
function i(value: EditWorkstationDto, index: number, array: EditWorkstationDto[]): value is EditWorkstationDto {
  throw new Error('Function not implemented.');
}

