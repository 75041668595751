import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
