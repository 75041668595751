import React from "react";

interface svgProps{
  height?: number;
  width?: number;
  color?: string;
}

const EditSvg = ({width=21, height=21, color='#554C25'}: svgProps) => {
  return(
    <svg width={width} height={height} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2074_262)">
      <path d="M13.6108 2.25C13.4188 2.25 13.219 2.325 13.073 2.4675L11.667 3.84L14.5481 6.6525L15.9541 5.28C16.2538 4.9875 16.2538 4.515 15.9541 4.2225L14.1563 2.4675C14.0027 2.3175 13.8106 2.25 13.6108 2.25ZM10.845 4.6425L2.34766 12.9375V15.75H5.22875L13.7261 7.455L10.845 4.6425Z" fill={color}/>
      </g>
      <defs>
      <clipPath id="clip0_2074_262">
      <rect width="18.439" height="18" fill="white" transform="translate(0.0429688)"/>
      </clipPath>
      </defs>
    </svg>
  );

}

export default EditSvg;
