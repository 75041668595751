import styled from "styled-components";

export const Cell = styled.div`
  background-color: #fff;
  border: 1px solid #80764B;
  border-radius: 8px;
  //padding: 6px;

  margin:0px;
  width: 294.03px;
`;

export const CellContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 282.03px;
  height: 100%;
  margin: 10px;
  padding: 10px;
`;

export const CellHeader = styled.div`
  display: flex;
  justify-content: center;
  gap: 45%;
  align-items: center;

  color: #fff;
  background-color: #80764B;

  padding: 6px;
  border-radius: 8px;
  margin-bottom: 10px;

  height: 44px;
  width: 282.03px;
`;

export const CellTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;


export const CellGoal = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #fff;
`;


export const Post = styled.div`
  display: flex;
  justify-content: center;
  gap: 45%;
  align-items: center;

  border-bottom: 1px solid #80764B;
  border-radius: 8px;
  width: 100%;

  margin:0px;
  height: 38.2px;
`;

export const PostLabel = styled.div`
  margin:0px;
  font-size: 14px;
`;

export const PostGoal = styled.div`
  margin:0px;
  font-size: 14px;
`;

export const PostList = styled.div`
  display: flex;
  flex-direction: column;
  margin:0px;
`;


export const ContentCellList = styled.div`
  width: 92%;
  margin: 0 auto;
  height: auto;
  min-height: 70vh;
  background-color: #fff;
  border-radius: 8px;
  padding:  20px ;
`;

export const ViewList = styled.div`
  padding: 0 4%;
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

export const FactoryGoalView = styled.div`
  border: 1px solid #80764B;
  border-radius: 8px;
  width: 14%;
  height: 76px;
  display:flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 24px 24px;
`;

export const TitleLabelGoal = styled.h2`
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  color: #454D5F;
`;

export const TitleGoal = styled.h2`
  font-weight: 600;
  font-size: 20px;
  margin: 0;
`;

export const ContainerChildrenHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
`;

export const ViewRowList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:  flex-start;
  width: 100%;

  //Rola pro lado as metas
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

export const DateInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
`;