import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  margin: 15px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FieldLabel = styled.p`
  font-size: 14px;
  weight: 400;
  color: #454D5F;
  margin: 0;
`;

export const FieldValue = styled.p`
  color: #191D27;
  font-size: 20px;
  weight: 500;
  margin: 0;
`;
