import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Title = styled.h1`
  font-family: var(--Jost-font);
  font-weight: 500;
  font-size: 32px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const BackButton = styled.button`
  background: transparent;
  border: 0;
  padding-top: 5px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;