import * as Styled from "./styled";
import FieldProps from "../../models/field-props.model";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const Field = ({ label, value }: FieldProps) => (
  <Styled.Container>
    <Styled.FieldValue>
      { value }
    </Styled.FieldValue>
    <Styled.FieldLabel>
      { label }
    </Styled.FieldLabel>
  </Styled.Container>
);

export default Field;
