import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
  padding: 0 5px;
  margin: 0 5px;
  margin-bottom: 30px;

  h3 {
    margin: 0;
    color: #191D27;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    font-size: 12px;
    color: #454D5F;
    line-height: 18px;
  }
`;

export const RadialChart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
