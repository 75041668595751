import { TextField } from '@mui/material';
import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------

export const CellsContainer = styled.div`
  padding-bottom: 80px;
  .slick-slide {
    width: max-content !important;
  }
`;
