import React, { ChangeEvent } from "react";
import * as Styled from "./styled";

interface CreateCellProps {
  labelText: string;
  onChange?: (value: string) => void;
  value?: string;
}

const CreateCell: React.FC<CreateCellProps> = ({ labelText, onChange, value }) => {

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Styled.Container>
      <Styled.InputField>
        <Styled.Label>{labelText}</Styled.Label>
        <Styled.Input
          variant="outlined"
          value={value || ""} // Garante que o valor seja uma string vazia se for nulo ou indefinido
          onChange={handleChange}
          fullWidth // Permite que o campo de entrada ocupe toda a largura disponível
        />
      </Styled.InputField>
    </Styled.Container>
  );
};

export default CreateCell;
