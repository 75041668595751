import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styled from "styled-components";

export const Card = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: white;
  padding: 35px;
  border-radius: 8px;
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const Column = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-right: 30px;
  flex-grow: 1;
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const UserAvatar = styled.div`
  width: 130px;
  height: 130px;
  background-color: #80764B;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Adiciona overflow para garantir que a imagem fique dentro do círculo */
`;

export const AvatarImage = styled.img`
  width: 100%; /* Ajusta o tamanho da imagem para preencher o círculo */
  height: auto;
`;

export const Subtitle = styled.h1`
  color: #454D5F;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
  margin-top: 10px;
`;

export const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  color: #B43131;
`;

export const DeleteIcon = styled(DeleteOutlineIcon)`
  font-size: 24px;
  margin-right: 5px;
`;

export const Row = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const Input = styled.input`
  height: 44px;
  width: ${(props) => props.width || "auto"};
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0 10px;
  margin-bottom: 10px;
`;

export const Select = styled.select`
  background-color: transparent;
  height: 44px;
  width: 330px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0 10px;
  margin-bottom: 10px;
`

export const Label = styled.label`
  color: #454D5F;
  margin-bottom: 5px;
`;

export const StyledButton = styled(Button)`
  &.generate-button {
    color: #80764B;
    background-color: white;
    border: 2px solid #80764B;
    border-radius: 8px;
    padding: 10px 20px;
    margin-top: 30px;
    width: 190px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: #80764B;
      color: white;
    }
  }
`;
