import React, { useEffect, useState } from "react";
import * as Styled from "./styled";
import Row from "../Row";
import ProgressBar from "../ProgressBar";
import Station from "../Station";
import { useHistory, useLocation } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import ButtonStations from "../../models/button-stations.model";
import CellProps from "../../models/cell-props.model";
import ProductionCellDto from "../../dto/production-cell.dto";
import WorkstationDto from "../../dto/workstation.dto";
import { DeleteIcon } from "../../pages/users/edit/styled";
import CellsService from "../../services/cells.service";
import { parseError } from "../../config/error-handler";
import { Toast } from "react-toastify/dist/components";
import { toast } from "react-toastify";

const Cell = ({ index, cellData, onDelete }: CellProps & { cellData: ProductionCellDto, onDelete : (id: number) => void }) => {
  const cellService = new CellsService()

  const [activeButton, setActiveButton] = useState<number | null>(null);

  //Nome célula
  const [name] = useState(`Célula ${cellData.id}`);

  //Está como valor padrão
  const [producao, setProducao] = useState(cellData.productionType); // Valor padrão para a produção

  const workstations : WorkstationDto[] | undefined = cellData.workstations

  const [meta, setMeta] = useState(cellData.goal); // Valor padrão para a meta
  const history = useHistory();
  
  const handleButtonClick = (index: number) => {
    setActiveButton(index);
  };

  //-------------------------------------
  //  Parar visualização da Workstation
  //-------------------------------------

  const handleReset = () => {
    setActiveButton(null);
  };

  //-------------------------------------
  //  Edit cell
  //-------------------------------------

  const id : number = cellData.id

  const editNewCell = () => {
    sessionStorage.setItem('editCellData', JSON.stringify({ id ,producao, meta, workstations }));
    history.push('/cells/edit');
  };
  
  //-------------------------------------------
  //
  //-------------------------------------------

  const performanceValue: number = activeButton !== null ? parseInt(ButtonStations[activeButton].performance, 10) || 0 : 250;

  return (
    <Styled.SlideControl>
      <Styled.Cell>
        <Styled.CellHeader>
          <Styled.TitleContainer>
            <h2>{name}</h2>
            <Styled.TransparentButton onClick={editNewCell}>
              <EditIcon fontSize="small" />
              <p>Editar</p>
            </Styled.TransparentButton>
          </Styled.TitleContainer>
          <Styled.TitleContainer>
            

              <Styled.Tag>
                <Styled.TagLabel>Produção:</Styled.TagLabel>
                <Styled.TagValue>{producao}</Styled.TagValue>
              </Styled.Tag>

              <div>
                <Styled.TransparentButton onClick={() => onDelete(cellData.id)}>
                  <DeleteIcon/>
                  <p>Excluir</p>
                </Styled.TransparentButton>
              </div>

         
          </Styled.TitleContainer>
        </Styled.CellHeader>

        <Styled.WorkStations>
          <h3>Postos de trabalho</h3>
          <Styled.Stations>
            
            {cellData.workstations?.map((button, Buttonindex) => (
              <Station 
                key={Buttonindex}
                onClick={() => {
                  handleButtonClick(Buttonindex);
                  setActiveButton(Buttonindex);
                }}
                number={Buttonindex + 1}
                selected={activeButton === Buttonindex ? 'true' : ''} 
                //stopReason={button.active} 
              />
            ))}
          </Styled.Stations>
        </Styled.WorkStations>

        <Styled.Summary>
          {activeButton !== null && (
            <Styled.Close onClick={handleReset} />
          )}
          <table>
            <tr>
              <th>{activeButton !== null ? `Meta Posto ${activeButton + 1}` : 'Meta Célula'}</th>
              <td>{activeButton !== null && workstations ? workstations[activeButton].goal : meta} unidades</td>
            </tr>
            <tr>
              <th>Produção total</th>
              <td>{activeButton !== null && workstations ? workstations[activeButton].unitsProduced : cellData.production} unidades</td>
            </tr>
            <tr>  
              <th>Produção N/C</th>
              <td>{activeButton !== null && workstations ? workstations[activeButton].unitsProducedNc : cellData.nc} unidades</td>
            </tr>
            <tr>
              <th>Horas trabalhadas</th>
              {/*<td>{activeButton !== null ? cellData.workedHours : cellData.workedHours}h</td>*/}
              <td>{activeButton !== null ? "0" : "0"}h</td>
            </tr>
            <tr>
              <th>Indisponibilidade</th>
              <td>{activeButton !== null ? "0" : "0"}</td>
            </tr>
          </table>
          <Styled.ProgressBarContainer>
            <p>{activeButton !== null ? 'Desempenho do posto' : 'Desempenho da célula'}</p>
            <ProgressBar percent={cellData ? ((cellData.production / cellData.goal) * 100) : 0} />
          </Styled.ProgressBarContainer>
        </Styled.Summary>
      </Styled.Cell>
    </Styled.SlideControl>
  );
};

export default Cell;
