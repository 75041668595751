import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AccentButton from "../../components/buttons/AccentButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UserTable from "../../components/Table";
import AuthenticatedLayout from "../_layouts/Authenticated";
import Row from "../../components/Row";
import * as Styled from "./styled";
import UserService from "../../services/user.service";
import UserDto from "../../dto/user.dto";
import { parseError } from "../../config/error-handler";
import Header from "../../components/template/Header";

const UsersPage = () => {
  const userService = new UserService()
  const history = useHistory();
  const { pathname } = useLocation();
  const [usersData, setUsersData] = useState<UserDto[]>([]);
  const userId = 1
  
  //Contador do Index (Existe no Pai e Filho)
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  
  //Usuários para serem deletados (Existe no filho e passa pro pai)
  const [selectedUsersToDelete, setSelectedUsersToDelete] = useState<UserDto[]>([]);

  //------------------------------
  //  Puxa todos usuários
  //------------------------------

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userService = new UserService();
        const userData = await userService.getAllUsers();
        setUsersData(userData);
      } catch (error) {
        console.error("Erro ao obter os dados dos usuários:", error);
      }
    }
    fetchUserData();
  }, []);

  //------------------------
  //  Adiciona usuário
  //-------------------------

  const addUser = () => {
    history.push({
      pathname: '/users/new',
      state: { setUsersData } // Passa a função setUsersData como estado para a página UsersPageNew
    });
  };

  //---------------------------
  //  Deleta USUÁRIOS (Plural)
  //---------------------------

  const deleteUser = () => {
    const updateUsers = usersData.filter((user, i) => !selectedUsers.includes(i))
    setUsersData(updateUsers)

    selectedUsersToDelete.map((user) =>
      userService
      .deleteUser(user?.id)
      .then()
      .catch(parseError)
    )
  };


  return (
    <AuthenticatedLayout>
      <Row style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: "40px" }}>
        <Header title="Gestão de Usuários"/>
        <Row style={{ justifyContent: 'space-between' }}>
          <AccentButton
            onClick={deleteUser}
            title="Excluir selecionados"
            style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '240px', color: '#191D27', paddingRight: '20px' }}
          >
            <Styled.StyledDeleteIcon />
          </AccentButton>
          <AccentButton
            onClick={addUser}
            title="Adicionar usuário"
            style={{ width: '240px' }}
          >
            <AddCircleOutlineIcon />
          </AccentButton>
        </Row>
      </Row>
      <UserTable
        usersData={usersData}
        setUsersData={setUsersData}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}

        selectedUsersToDelete={selectedUsersToDelete}
        setSelectedUsersToDelete={setSelectedUsersToDelete}

      />

    </AuthenticatedLayout>
  );
}

export default UsersPage;
