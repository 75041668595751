import React from 'react';
import ProgressBar from '../ProgressBar';
import * as Styled from "./styled";
import ButtonStations from "../../models/button-stations.model";

interface SummaryProps {
  selectedStationIndex: number | null;
  meta?: string;
  productionTotal?: string;
  productionNC?: string;
  workedHours?: string;
  unavailability?: string;
}

const Summary: React.FC<SummaryProps> = ({ selectedStationIndex, meta, productionTotal = '0', productionNC = '0', workedHours = '0', unavailability = '0' }) => {
  const activeButton = selectedStationIndex; 

  let calculatedPerformanceValue = 0;
  if (activeButton !== null && ButtonStations[activeButton]) {
    calculatedPerformanceValue = parseInt(ButtonStations[activeButton].performance, 10) || 0;
  }

  const percentage = selectedStationIndex !== null ? (calculatedPerformanceValue / 300) * 100 : 83.33;

  return (
    <Styled.Summary>
      <table>
        <tbody>
          <tr>
            <th>Produção total</th>
            <td>{productionTotal} unidades</td>
          </tr>
          <tr>
            <th>Produção N/C</th>
            <td>{productionNC} unidades</td>
          </tr>
          <tr>
            <th>Horas trabalhadas</th>
            <td>{workedHours}</td>
          </tr>
          <tr>
            <th>Indisponibilidade</th>
            <td>{unavailability}</td>
          </tr>
        </tbody>
      </table>
      <Styled.ProgressBarContainer>
        <p>{activeButton !== null ? 'Desempenho do posto' : 'Desempenho da célula'}</p>
        <ProgressBar percent={percentage} />
      </Styled.ProgressBarContainer>
    </Styled.Summary>
  );
};

export default Summary;
