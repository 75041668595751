import React from "react";
import * as Styled from "./styled";

const LightLogo = () => (
  <Styled.LogoContainer>
    <img src="/viaaroma-light.svg" />
  </Styled.LogoContainer>
);

export default LightLogo;
