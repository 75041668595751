import { useState } from "react";
import * as Styled from "./styled";
import AccentButton from "../../buttons/AccentButton";
import AuthService from "../../../services/auth.service";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const SignInForm = ({ onSuccess }: any) => {

  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const authService = new AuthService();

  return (
    <Styled.Container>
      <EmailInput
        error={error}
        value={email}
        onChange={(event: any) => handleEmailChange(event, setEmail)}
      />
      <PasswordInput 
        error={error}
        value={password}
        onChange={(event: any) => handlePasswordChange(event, setPassword)}
      />
      <SubmitButton 
        onClick={() => handleSubmit(
          authService, 
          email, 
          password, 
          onSuccess, 
          setError
        )} 
      />
    </Styled.Container>
  );
};

export default SignInForm;

const EmailInput = ({ error, email, onChange }: any) => (
  <Styled.InputField>
    <Styled.Input
      id="outlined-basic"
      label="Email"
      variant="outlined"
      error={error}
      type="text"
      value={email}
      onChange={onChange}
      size="medium"
      placeholder="Digite seu email"
    />
  </Styled.InputField>
);

const PasswordInput = ({ error, password, onChange }: any) => (
  <Styled.InputField>
    <Styled.Input
      id="outlined-basic"
      label="Senha"
      variant="outlined"
      type="password"
      error={error}
      value={password}
      onChange={onChange}
      size="medium"
      placeholder="Digite sua senha"
    />
  </Styled.InputField>
);

const SubmitButton = ({ onClick }: any) => (
  <AccentButton
    onClick={onClick}
    title="Entrar"
    style={{ width: '100%', marginTop: 20 }}
  />
);


// ----------------------------------------------------------------------------
//         Functions
// ----------------------------------------------------------------------------
function handleEmailChange(event: any, setEmail: any) {
  setEmail(event.target.value);
};

function handlePasswordChange(event: any, setPassword: any) {
  setPassword(event.target.value);
};

async function handleSubmit(
  authService: AuthService, 
  email: string, 
  password: string, 
  onSuccess: any, 
  setError: (status: boolean) => void
) {
  const success = await authService.makeLogin(email, password);

  if (success) {
    onSuccess();
  } 
  else {
    setError(true);
  }
}
