import React, { useState } from 'react';
import * as Styled from "./styled";
import { Snackbar } from '@mui/material';

interface AddStationProps {
  onClick: () => void;
  isProductionFilled: boolean;
}

const AddStation: React.FC<AddStationProps> = ({ onClick, isProductionFilled }) => {
  const [showAlert, setShowAlert] = useState(false);

  const handleClick = () => {
    if (isProductionFilled) {
      onClick();
    } else {
      setShowAlert(true);
    }
  };

  return (
    <Styled.Container>
      <Styled.AddPointIcon onClick={handleClick} />
      <Styled.ContainerText onClick={handleClick}>Criar novo Posto</Styled.ContainerText>
      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        message="Por favor, preencha o campo de Produção antes de adicionar um novo Posto."
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        style={{ marginTop: '60px' }}
      />
    </Styled.Container>
  );
}

export default AddStation;
