import * as Styled from "./styled";
import HeaderProps from "../../../models/header-props.model";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const Header = ({ title, canGoBack, children }: HeaderProps) => {

  return (
    <Styled.Container>
      <Styled.TitleContainer>
        {canGoBack && 
          <Styled.BackButton onClick={handleGoBack}>
            <ArrowBackIosIcon />
          </Styled.BackButton>
        }
        <Styled.Title>
          { title }
        </Styled.Title>
      </Styled.TitleContainer>
      { children }
    </Styled.Container>
  );
};

export default Header;


// ----------------------------------------------------------------------------
//         Functions
// ----------------------------------------------------------------------------
function handleGoBack() {
  window.history.back();
}
