import React, { useCallback, useEffect, useState } from "react";
import * as Styled from "./styled";
import AuthenticatedLayout from "../_layouts/Authenticated";
import Header from "../../components/template/Header";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AccentButton from "../../components/buttons/AccentButton";
import Cell from "../../components/Cell";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useHistory, useLocation } from 'react-router-dom';
import cellsService from "../../services/cells.service";
import ProductionCellDto from "../../dto/production-cell.dto";
import { RowDasCelulas } from "../../components/Navbar/styled";
import CellsService from "../../services/cells.service";
import { parseError } from "../../config/error-handler";
import { WorkStations } from "../../components/Cell/styled";

const CellsPage = () => {
  const cellService = new CellsService()
  
  const [cellData, setCellData] = useState<ProductionCellDto[]>([]);

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    cellService
      .getProductions()
      .then(setCellData)
      .catch(parseError)

}, []);

  const addNewCell = () => {
    history.push('/cells/new');
  }

  const handleDelete = (id : number) => {
    const updatedCellsData = cellData.filter((cell) => cell.id !== id)

    cellService
      .deleteCell(id)
      .catch(parseError)

    //setCellData(updatedCellsData)    
  }

  return (
    <AuthenticatedLayout>
      <Header title='Células de produção'>
        <AccentButton
          onClick={addNewCell}
          title="Nova Célula"
          style={{ width: '170px' }}
        >
          <AddCircleOutlineIcon />
        </AccentButton>
      </Header>

      <Styled.CellsContainer>
        <RowDasCelulas>
          {cellData.map((cell, index) => (
            <Cell key={index} index={index} cellData={cell} onDelete={handleDelete} />
          ))}
          </RowDasCelulas>
      </Styled.CellsContainer>

    </AuthenticatedLayout>
  );
};

export default CellsPage;
