import React, { useState } from "react";
import * as Styled from "./styled";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from "react-router-dom";
import Popup from "../Popup";
import UserDto from "../../dto/user.dto";
import UserService from "../../services/user.service";
import { parseError } from "../../config/error-handler";

interface UserTableProps {
  usersData: UserDto[];
  setUsersData: React.Dispatch<React.SetStateAction<UserDto[]>>;
  selectedUsers: number[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<number[]>>;
  selectedUsersToDelete: UserDto[];
  setSelectedUsersToDelete: React.Dispatch<React.SetStateAction<UserDto[]>>;
}

const UserTable: React.FC<UserTableProps> = ({ usersData, setUsersData, selectedUsers, setSelectedUsers, selectedUsersToDelete, setSelectedUsersToDelete}) => {
  const userService = new UserService();

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [userToDelete, setUserToDelete] = useState<UserDto | null>();
  const history = useHistory();

  const isSelected = (index: number) => {
    return selectedUsers.includes(index);
  };

  //-----------------------------
  //  Delete USERS (Plural)
  //  Mexe com o "setSelectedUsers"
  //-----------------------------

  const handleClick = (index: number, event: React.MouseEvent<HTMLDivElement>, user : UserDto) => {
    const target = event.target as HTMLDivElement;
    if (target.tagName === "BUTTON") {
      return;
    }
    handleSelect(index, user);
  };

  const handleSelect = (index: number, user: UserDto) => {
    //Verifica se o user está marcado
    if (selectedUsers.includes(index)) {
      //Retira o index
      //Irá retira o user do Delete
      setSelectedUsers(selectedUsers.filter((item) => item !== index));
      setSelectedUsersToDelete(selectedUsersToDelete.filter((item) => item !== user));
    } else {
      //Coloca o index
      //Irá colocar o user no Delete
      setSelectedUsers([...selectedUsers, index]);
      setSelectedUsersToDelete([...selectedUsersToDelete, user]);
    }
  };

  //-----------------------------
  //  Edit user
  //-----------------------------

  const handleEdit = (user : UserDto, index : number) => {
    history.push({
      pathname: '/users/edit',
      state: {
        user: user,
        onDelete: () => handleDelete(index)
      }
    });
  };

  //-----------------------------
  //  All deletes ;)
  //-----------------------------
  
  const handleDeleteButtonClick = (user : UserDto) => {
    setUserToDelete(user);
    setShowConfirmationPopup(true);
  };

  const handleCancelDelete = () => {
    setUserToDelete(null);
    setShowConfirmationPopup(false);
  };

  const handleConfirmationDelete = () => {
    if (userToDelete) {
      //Pega o index do usuário a ser deletado
      const index = usersData.findIndex(user => user === userToDelete);
      if (index !== -1) {
        handleDelete(index);
      }
    }

    userService
    .deleteUser(userToDelete?.id)
    .then()
    .catch(parseError);
  };

  const handleDelete = (index: number) => {
    const updatedUsers = usersData.filter((user, i) => i !== index);
    setUsersData(updatedUsers); //Atualiza os usuários
    setSelectedUsers(selectedUsers.filter((item) => item !== index)); // Limpa o usuário selecionado
    setShowConfirmationPopup(false); 
  };

  return (
    <Styled.Wrapper>
      {showConfirmationPopup && userToDelete && (
        <Popup
          stationName={userToDelete.name}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmationDelete}
        />
      )}
      <Styled.Table>
        <thead>
          <Styled.Header>
            <Styled.HeaderCell> </Styled.HeaderCell>
            <Styled.HeaderCell>NOME DE USUÁRIO</Styled.HeaderCell>
            <Styled.HeaderCell>EMAIL</Styled.HeaderCell>
            <Styled.HeaderCell>TELEFONE</Styled.HeaderCell>
            <Styled.HeaderCell>POSTO</Styled.HeaderCell>
            <Styled.HeaderCell>CELULA</Styled.HeaderCell>
            <Styled.HeaderCell>TURNO ATIVO</Styled.HeaderCell>
            <Styled.HeaderCell> </Styled.HeaderCell>
          </Styled.Header>
        </thead>
        <tbody>
          {usersData.map((user, index) => (

            <Styled.Row key={index}>
              <Styled.Cell onClick={(event) => handleClick(index, event, user)}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Styled.ActionButton style={{ color: '#939393', border: 'none' }}>
                    {isSelected(index) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                  </Styled.ActionButton>
                </div>
              </Styled.Cell>
              <Styled.Cell>
                <Styled.UserContainer>
                  <Styled.IconWrapper>
                    {user.picture ? (
                      <img src={user.picture} alt="Profile" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                    ) : (
                      <AccountCircleIcon style={{ fontSize: '40px', color: '#939393' }} />
                    )}
                  </Styled.IconWrapper>
                  <Styled.Name>{user.name}</Styled.Name>
                </Styled.UserContainer>
              </Styled.Cell>
              <Styled.Cell>{user.email}</Styled.Cell>
              <Styled.Cell>{user.phone}</Styled.Cell>
              <Styled.Cell>{user.workstation}</Styled.Cell>
              <Styled.Cell>{user.shift}</Styled.Cell>
              <Styled.Cell>{user.shift}</Styled.Cell>
              <Styled.Cell style={{ width: '10%' }}>
                <Styled.ButtonsContainer>
                  <Styled.EditButton onClick={() => handleEdit(user, index)} style={{ color: '#554C25', border: 'none', display: 'flex', alignItems: 'center' }}>
                    <EditIcon style={{ fontSize: '20px' }} />
                    Editar
                  </Styled.EditButton>
                  <Styled.DeleteButton onClick={() => handleDeleteButtonClick(user)} style={{ background: 'none', border: 'none', color: '#B43131', display: 'flex', alignItems: 'center' }}>
                    <DeleteOutlineIcon style={{ fontSize: '20px' }} />
                    Excluir
                  </Styled.DeleteButton>
                </Styled.ButtonsContainer>
              </Styled.Cell>
            </Styled.Row>
          ))}
        </tbody>
      </Styled.Table>
    </Styled.Wrapper>
  );
};

export default UserTable;