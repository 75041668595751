import styled from "styled-components";


export const Summary = styled.div`

  position: relative;
  table {
    tr {
      color: #554C25;
      font-size: 16px;
      
      th {
        text-align: left;
        padding: 10px 0;
        padding-right: 50px;
        font-weight: 700;
      }

      td {

      }
    }
  }
`;

export const ProgressBarContainer = styled.div`
  p {
    font-weight: 400;
    color: #554C25;
    font-size: 16px;

  }
`;