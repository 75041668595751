import styled from 'styled-components';
import { TextField } from '@mui/material';
import AddPointIconMui from '@mui/icons-material/ControlPoint'; 

export const AddPointIcon = styled(AddPointIconMui)` // Renomeie o componente estilizado
  margin-right:5px;
  color: #80764B;
`;

export const ContainerText = styled.text`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #80764B;
  `

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`