import { TextField } from '@mui/material';
import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------

export const Cell = styled.div`
  display: flex;
  width: 475px;
  min-width: 475px;
  background-color: white;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-right: 20px;
  padding: 20px 30px;

  @media (max-width: 767px) {
    margin: auto;
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 475px;
    width: 100%;
    min-width: unset;
    box-sizing: border-box;
  }
`;

export const SlideControl = styled.div`
`;

export const CellHeader = styled.div`
  height: 130px;
  border-bottom: 1px solid #EAECF2;
`;

export const TransparentButton = styled.button`
  background: transparent;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #554C25;
  cursor: pointer;

  p {
    margin-left: 5px;
    weight: 700;
    font-size: 14px;
  }

  :hover {
    text-decoration: underline;
  }
`;

export const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  background-color: #F0EDE2;
  border-radius: 20px;
  padding: 12px 20px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  h2 {
    color: #554C25;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
`;

export const Alinhamento = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

`

export const TagLabel = styled.p`
  margin: 0;
  font-weight: 700;
  color: #554C25;
  font-size: 16px;
`;

export const TagValue = styled.p`
  margin: 0;
  font-weight: 400;
  color: #554C25;
  font-size: 16px;
  margin-left: 5px;
`;

export const WorkStations = styled.div`
  margin: 30px 0;

  h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    color: #191D27;
    width: 125px;
  }
`;

export const Stations = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 50px 0;

  @media (max-width: 767px) {
    flex-wrap: wrap;

    button {
      margin-bottom: 5px;
    }
  }
`;
  
  export const Summary = styled.div`
  border-top: 1px solid #EAECF2;
  padding-top: 20px;
  position: relative;
  table {
    tr {
      color: #554C25;
      font-size: 16px;
      
      th {
        text-align: left;
        padding: 10px 0;
        padding-right: 50px;
        font-weight: 700;
      }

      td {

      }
    }
  }
`;

export const ProgressBarContainer = styled.div`
  p {
    font-weight: 400;
    color: #554C25;
    font-size: 16px;

  }
`;

export const Close = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 32px;
  right: 22px;
  cursor: pointer;

  @media (max-width: 767px) {
    top: 10px;
    right: 0;
  }

  :after,
  :before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 2px;
    transform-origin: 50% 50%;
    background-color: #80764B;
  }

  :after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  :before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;
