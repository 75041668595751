import { useEffect, useState } from "react";
import * as Styled from "./styled";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import TopTooltip from "../TopTooltip";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const Station = ({ number, selected, stopReason, onClick }: any) => {

  const [bgColor, setBgColor] = useState('');
  const [fgColor, setFgColor] = useState('');
  const [tooltipText, setTooltipText] = useState('')

  useEffect(() => {
    setBgColor(buildBgColor(selected, stopReason));
    setFgColor(buildFgColor(selected, stopReason));
    setTooltipText(stopReason)
  }, [selected, stopReason]);

  return (
    <TopTooltip title={`Motivo de parada: ${stopReason}`} display={!!stopReason} placement="top">
      <Styled.Container 
        bgColor={bgColor} 
        fgColor={fgColor} 
        onClick={onClick}
      >
        { number }
      </Styled.Container>
    </TopTooltip>
  );
};

export default Station;


// ----------------------------------------------------------------------------
//         Functions
// ----------------------------------------------------------------------------
function buildBgColor(selected?: boolean, stopReason?: string) {
  if (selected) {
    return '#F0EDE2';
  }

  if (stopReason) {
    return '#B43131';
  }

  return '#454D5F';
}

function buildFgColor(selected?: boolean, stopReason?: string) {
  if (selected) {
    return '#554C25';
  }

  return '#FFFFFF';
}
