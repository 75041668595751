import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from "./routes";


// --------------------------------------------------------------------------
//         Components
// --------------------------------------------------------------------------
const App = () => (
  <>
    <ToastContainer position="top-right" />
    <Routes />
  </>
);

export default App;
