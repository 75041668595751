import React, { useEffect, useState } from "react";
import * as Styled from "./styled";
import CellsService from "../../../services/cells.service";
import AuthenticatedLayout from "../../_layouts/Authenticated";
import Header from "../../../components/template/Header";
import LineCheck from "./components/LineCheck";
import DeleteSvg from "../../../components/svgs/delete";
import OptionDto from "../../../dto/option.dto";
import ProductionCellDto from "../../../dto/production-cell.dto";
import { parseError } from "../../../config/error-handler";

const cellsService = new CellsService();

const RegisterGoals = () => {
  //Células 
  const [responseCell, setResponseCell] = useState<ProductionCellDto[]>([]);
  const [selectedCell, setSelectedCell] = useState<number[]>([]); 
  const [filter, setFilter] = useState<string>();

  const filterOptions  = ['id', 'production']

  useEffect(() => {
    cellsService
      .getProductions()
      .then(setResponseCell)
      .catch(parseError)
  }, []);

  const handleDeleteAll = () => {
    selectedCell.map(id => (
      cellsService.deleteWorkstation(id)
    ))
  }
  
  const DropdDown = (): JSX.Element => (
    <Styled.Filter>
      <span>Filtrar por...</span>
    <Styled.InputDefault
      size="small"
      select
      value={filter}
      onChange={event => setFilter(event.target.value)}
      SelectProps={{
        native: true,
      }}
    >
      {filterOptions.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </Styled.InputDefault>
  </Styled.Filter>
  ); 

  const ChildrenHeader = (): JSX.Element => (
    <Styled.ContainerChildrenHeader>
      <Styled.ButtonDeleteAll onClick={() => handleDeleteAll()}>
        <DeleteSvg />
        <span>Excluir selecionados</span>
      </Styled.ButtonDeleteAll>
      <DropdDown />
    </Styled.ContainerChildrenHeader>
  );

  const handleSelectCell = (cellId: number) => {
    setSelectedCell(prev => {
      const isAlreadySelected = prev.includes(cellId);
      if (isAlreadySelected) {
        return prev.filter(id => id !== cellId); 
      } else {
        return [...prev, cellId]; 
      }
    });
  };

  return (
    <AuthenticatedLayout>
      <Header title={'Registro de Metas'} children={<ChildrenHeader />} />
      <Styled.ContentCellList>
        <LineCheck cellList={responseCell} onClick={handleSelectCell} selectedCells={selectedCell} />
      </Styled.ContentCellList>
    </AuthenticatedLayout>
  );
}

export default RegisterGoals;
