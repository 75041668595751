import React, { useState } from "react";
import * as Styled from "./styled";

interface StyleViewCellProps {
  labelText: string;
  image: string;
  onClick?: () => void;
  isSelected?: boolean; 
}

const StyleViewCell: React.FC<StyleViewCellProps> = ({ labelText, image, onClick, isSelected }) => {
  const [clicked, setClicked] = useState(false);

  const handleClickStyle = () => {
    setClicked(!clicked);
    if (onClick) {
      onClick();
    }
  };

  return (
    <Styled.InputField>
      <Styled.Label>{labelText}</Styled.Label>
      <Styled.ImageContainer
        onClick={handleClickStyle}
        style={{
          borderColor: isSelected ? "#80764B" : "#ccc", 
          backgroundColor: isSelected ? "#F0EDE2" : "rgb(240, 240, 240)", 
        }}
      >
        <img
          src={image}
          alt={labelText}
          style={{ 
            filter: isSelected ? "brightness(80%) hue-rotate(60deg)" : "none" 
          }}
        />
      </Styled.ImageContainer>
    </Styled.InputField>
  );
};

export default StyleViewCell;
