import axios, { AxiosInstance } from 'axios';


/**
 * Responsible for handling HTTP requests.
 */
abstract class Service {
  
  // --------------------------------------------------------------------------
  //         Attributes
  // --------------------------------------------------------------------------
  protected readonly remoteRequest: AxiosInstance;


  // --------------------------------------------------------------------------
  //         Constructor
  // --------------------------------------------------------------------------
  public constructor() {
    this.remoteRequest = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: process.env.REACT_APP_REQUEST_TIMEOUT ? parseInt(process.env.REACT_APP_REQUEST_TIMEOUT) : 0,
      headers: { 'authorization': 'Bearer ' + localStorage.getItem('x_auth_token') }
    });
  }
}

export default Service;
