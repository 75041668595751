import styled from 'styled-components';



export const InputField = styled.div`
  margin: 20px 0;
  width: 120px;
  height: 115px;
  padding: 0px 10px 0px 10px;
`;

export const Label = styled.div`
  font-size: 17px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px; 
`;

export const ImageContainer = styled.div`
width: 85%;
height: 65%;
pading: 118px;
background-color: rgb(240, 240, 240);
display: flex;
justify-content: center;
align-items: center;
border: 1px solid #ccc; 
border-radius: 6px;

  img {
    object-fit: contain;
    max-width: 80%;
    max-height: 62%;
  }
`;


