import React from "react";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Container,Check, Checked } from "./styles";

interface CheckProps {
  isChecked: boolean,
  label?: string,
}

const CheckBox = ({isChecked, label}: CheckProps) => {
  return(
    <Container>
       {isChecked  ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/> } 
      {label && label}
    </Container>
  );
}

export default CheckBox