import * as Styled from "./styled";
import CircularChart from "../../components/charts/CircularChart";
import ProductionEmployeeDto from "../../dto/production-employee-data.dto";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const ProductionTable = ({ data }: { data: ProductionEmployeeDto[] }) => (
  <Styled.ProductionTable>
    <thead>
      <tr>
        <th className="name">Nome</th>
        <th>Produção</th>
        <th>Meta</th>
        <th>Posto</th>
        <th>Célula</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(data) && data.map((production, index) => (
        <tr key={index}>
          <td>{production.name}</td>
          <td>{production.production} unidades</td>
          <td className="chart">
            <CircularChart 
              series={[production.goal === 0 ? 100 : parseFloat((production.production/production.goal*100).toFixed(1))]} 
              size='small'
            />
            <span>{production.goal === 0 ? 100 : parseFloat((production.production/production.goal*100).toFixed(1))}%</span>
          </td>
          <td>{formatNumberWithZeroAtLeft(production.workstation)}</td>
          <td>{formatNumberWithZeroAtLeft(production.cell)}</td>
        </tr>
      ))}
    </tbody>
  </Styled.ProductionTable>
);

export default ProductionTable;



// ----------------------------------------------------------------------------
//         Functions
// ----------------------------------------------------------------------------
function formatNumberWithZeroAtLeft(number: number) {
  if (number > 9) {
    return number;
  }

  return `0${number}`;
}
