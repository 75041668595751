import styled from 'styled-components'
import TextField from "@mui/material/TextField";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  background-color: #fff;
  width: 550px;
  padding: 35px;
  margin: 20px 0;
`;

export const Input = styled(TextField)`
  width: 100%;
`;

export const InputField = styled.div`
  margin: 20px 0;
`;

