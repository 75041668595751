import { TextField } from '@mui/material';
import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  flex: 1;
  background-color: #80764B;
  padding: 30px;
  min-height: 100vh;
`;

export const Right = styled.div`
  flex: 1;
`;

export const Left = styled.div`
  flex: 1;
`;

export const Title = styled.h1`
  height: 32px;
  font-weight: 500;
  font-size: 32px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Filter = styled.div`
  margin-left: 20px;
`;

export const InputDefault = styled(TextField)<{ highlight?: boolean }>`
  width: 100%;

  & .MuiInputBase-root {
    border-width: 1px;
    border: 1px solid rgba(72,46,25,0.5);
    border-radius: 8px;
    color: ${props => props.highlight ? 'white' : 'inherit'};
    background: ${props => props.highlight ? '#554C25' : 'inherit'};
  }

  & .MuiSvgIcon-root {
    color: ${props => props.highlight ? 'white' : 'inherit'};
  }
`;


export const Card = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: white;
  padding: 15px 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  h2 {
    color: #454D5F;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaecf2;
    font-family: var(--Jost-font);
  }
`;

export const FullCard = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: white;
  padding: 15px 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;

  h2 {
    color: #454D5F;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaecf2;
    font-family: var(--Jost-font);
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Fields = styled.div`
  flex: 1;
  width: 100%;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 465px;
  margin-right: 15px;
`;

export const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

export const RadialChart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinkButton = styled.a`
  background: transparent;
  color: #80764B;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  text-align: center;

  cursor: pointer;
`;

export const TableContainer = styled.div`
  max-height: 305px;
  overflow-y: auto;
`;
